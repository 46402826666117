<template>
  <a-spin :spinning="loading">
    <div>
      <div class="search-bar">
        <div class="mb-3">
          <a-input-search
            v-model="search"
            size="large"
            placeholder="Search"
            class="block"
            allow-clear
          />
        </div>
      </div>

      <div>
        <div v-if="entities && entities.length">
          <p>
            Showing {{ currentPage * pageSize - pageSize + 1 }}-{{
              pageSize * currentPage
            }}
            of {{ totalRecords }} items
          </p>

          <div class="grid-container">
            <facility-details-card
              class="entity-card"
              v-for="(entity, index) of entities"
              :key="index"
              :image="getFeaturedImageToDisplay(entity.media)"
              :title="getValueFromSource(entity, 'information.name', 'N/A')"
              :description="
                getValueFromSource(entity, 'information.description', 'N/A')
              "
              :detailLink="detailLink(entity)"
              :region="
                getValueFromSource(
                  entity,
                  'information.address.state.name',
                  'N/A'
                )
              "
              :rating="getValueFromSource(entity, 'ratings.total_avg', 0)"
            />
          </div>
        </div>

        <div v-else class="text-center">
          <h4 v-if="!loading">No Featured Entities found</h4>
        </div>

        <a-pagination
          v-model="currentPage"
          :pageSizeOptions="pageSizeOptions"
          :pageSize.sync="pageSize"
          :total="totalRecords"
          :show-total="
            (total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} items`
          "
          hideOnSinglePage
          :showSizeChanger="filterBy !== 'all'"
          show-less-items
          @showSizeChange="onPageSizeChanged"
        />
      </div>
    </div>
  </a-spin>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import ProfileMixin from "@/mixins/Profile";
import { get, debounce } from "lodash";
import { PUBLIC_QUERY_ENTITIES } from "@/store/actions";

import FacilityDetailsCard from "@/components/facilities/FacilityDetailsCard.vue";

const ENTITY_URLS = {
  hotel_and_accommodations: "hotel-and-accommodations",
  events: "events-organizers",
  tour_operator: "tour-operators",
  travel_trade: "tour-operators",
  operator: "tour-operators",
  food_and_drinks: "food-and-beverages",
  transport: "transports",
  tour_sight: "tour-sites",
};

const hotel_and_accommodations = [
  "five_star_hotel",
  "four_star_hotel",
  "three_start_hotel",
  "two_star_hotel",
  "one_star_hotel",
  "guest_house",
  "apartments",
  "budget_hotel",
  "hostel",
  "home_stay",
  "apartments",
];

hotel_and_accommodations.forEach(
  (category) => (ENTITY_URLS[category] = "hotel-and-accommodations")
);

const transport = ["air_vehicle", "land_vehicle", "water_vehicle"];

transport.forEach((category) => (ENTITY_URLS[category] = "transports"));

export const tour_sight = ["natural_resource", "man_made", "historic"];
tour_sight.forEach((category) => (ENTITY_URLS[category] = "tour-sites"));

export const food_and_drinks = [
  "chop_bar",
  "contract_caterers",
  "conference",
  "drinking_spots",
  "fast_food",
  "restaurant",
];
food_and_drinks.forEach(
  (category) => (ENTITY_URLS[category] = "food-and-beverages")
);

export const travel_trades = [
  "travel_and_tour",
  "travel",
  "tour",
  "tour_guide",
];
travel_trades.forEach((category) => (ENTITY_URLS[category] = "tour-operators"));

export const events = [
  "cinema",
  "clubbing_events",
  "live_events",
  "sports_events",
];

events.forEach((category) => (ENTITY_URLS[category] = "events-organizers"));

export default {
  mixins: [ProfileMixin],
  components: {
    FacilityDetailsCard,
  },
  props: {},
  data() {
    return {
      loading: false,
      pageSize: 10,
      pageSizeOptions: ["10", "50", "100"],
      search: "",
      filterBy: "all",
      currentPage: 1,
      totalRecords: 0,
      entities: [],
      entity_type: "",
      debounceCall: null,
    };
  },
  computed: {
    ...mapState("tourist", [
      "loadingGet",
      "tour_sites",
      "hotel_and_accommodations",
      "food_and_drinks",
      "events",
      "tour_operators",
      "transports",
    ]),
    ...mapGetters("auth", ["currentUser", "isLoggedIn"]),
  },
  watch: {
    filterBy: {
      handler(filter) {
        console.log("--- OV.Filter ---");
        if (this.loading) {
          return;
        }
        this.currentPage = 1;
        if (filter === "all") {
          this.entity_type = "";
        } else {
          this.entity_type = filter;
        }

        this.fetchFeaturedEntities(true);
      },
      immediate: false,
    },
    pageSize: {
      handler() {
        console.log("--- OV.PageSize ---");
        if (this.loading) {
          return true;
        }
        this.currentPage = 1;
        this.fetchFeaturedEntities(false, false, true);
      },
      immediate: false,
    },
    currentPage: {
      handler() {
        console.log("--- OV.CurrentPage ---", this.currentPage);
        if (this.loading) {
          return true;
        }
        this.fetchFeaturedEntities(false, true);
      },
      immediate: false,
    },
    search: {
      handler() {
        console.log("--- OV.Search ---");
        if (this.loading) {
          return true;
        }

        if (this.debounceCall) {
          this.debounceCall();
        }
      },
      immediate: false,
    },
  },
  created() {
    this.debounceCall = debounce(this.searchBarEventHandler, 500);
  },
  mounted() {
    this.fetchFeaturedEntities();
  },
  methods: {
    ...mapActions("public", {
      fetchEntities: PUBLIC_QUERY_ENTITIES,
    }),
    async fetchFeaturedEntities(
      overrideFilter = false,
      overrideCurrentPage = false,
      overridePageSize = false,
      overrideSearch = false
    ) {
      try {
        this.loading = true;
        const { page, limit, search, filter } = this.$route.query;

        if (filter && !overrideFilter) {
          this.filterBy = filter;
          if (this.filterBy !== "all") {
            this.entity_type = this.filterBy;
          }
        }

        /** reset current page and page limit if filter changes. */
        if (this.filterBy !== filter) {
          this.currentPage = 1;
          this.pageSize = this.entity_type ? +this.pageSizeOptions[0] : 60;
        } else {
          if (!this.entity_type) {
            this.pageSize = 60;
          }

          if (page && !overrideCurrentPage) {
            this.currentPage = +page;
          }

          if (limit && !overridePageSize && this.entity_type) {
            const isPageSizeAllowed = this.pageSizeOptions
              .map((pgSize) => +pgSize)
              .includes(+limit);
            if (isPageSizeAllowed) {
              this.pageSize = +limit;
            } else {
              this.pageSize = +this.pageSizeOptions[0];
            }
          } else if (overridePageSize) {
            const isPageSizeAllowed = this.pageSizeOptions
              .map((pgSize) => +pgSize)
              .includes(+this.pageSize);
            if (!isPageSizeAllowed) {
              this.pageSize = +this.pageSizeOptions[0];
            }
            if (+limit !== +this.pageSize) {
              this.currentPage = 1;
            }
          }
        }

        if (!overrideSearch && search && !this.search) {
          this.search = search;
        }

        const filterQuery = {
          entity_type: this.entity_type ? this.filterBy : "",
          page: this.currentPage,
          limit: this.pageSize,
          search: this.search,
        };
        const response = await this.fetchEntities(filterQuery);
        const { data, pagination } = response;

        this.entities = data || [];
        this.totalRecords = pagination.totalRecords || 0;

        filterQuery.filter = filterQuery.entity_type || "all";
        delete filterQuery.entity_type;
        this.$router.replace({ query: filterQuery }).catch((error) => {});
      } catch (error) {
        console.log("error", error.message);
        this.$notification.error({
          message: `An error occurred. Please try again.`,
        });
      } finally {
        this.loading = false;
      }
    },
    searchBarEventHandler() {
      this.fetchFeaturedEntities(false, false, false, true);
    },
    detailLink(entity) {
      const base_url = ENTITY_URLS[entity.information.entity_type];
      return `/tourist/gta-facilities/${base_url}/${entity._id}`;
    },
    getFeaturedImageToDisplay(media) {
      if (media && media.featured_1 && media.featured_1.path.length > 0) {
        return media.featured_1.path;
      }
      if (media && media.featured_2 && media.featured_2.path.length > 0) {
        return media.featured_2.path;
      }
      if (media && media.featured_3 && media.featured_3.path.length > 0) {
        return media.featured_3.path;
      }
      //TODO: specify default image to use, we have to use something
    },
    getValueFromSource(source, path, defaultValue = "") {
      return get(source, path, defaultValue);
    },
    onPageSizeChanged(current, size) {
      this.pageSize = size;
    },
  },
};
</script>
<style scoped>
.grid-container {
  display: flex;
  flex-direction: row;
  align-self: auto;
  flex-wrap: wrap;
}

.entity-card {
  margin-right: 25px;
}

.search-bar {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.row-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
</style>
