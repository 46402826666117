<template>
  <div class="card" @click="clickHandler" :style="`background: url(${image})`">
    <div class="outer">
      <div class="ratings">
        <a-rate :value="rating" :disabled="true" />
      </div>
      <div>
        <p class="title">{{ title }}</p>
        <div class="bottom-row">
          <div class="location">
            <span class="fe fe-map-pin"></span>
            <span class="region">{{ region }}</span>
          </div>
          <a-button type="primary" style="color: white" size="small"
            >Browse Services</a-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    rating: {
      type: Number,
      required: false, //FOR now
      default: 0,
    },
    region: {
      type: String,
      required: false,
      default: "Unknown",
    },
    detailLink: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    image: {
      type: String,
      required: false,
      default: "",
    },
    is_anonymouse: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickHandler(event) {
      const buttonNodeName = "BUTTON";
      const eventSourceNodeName = event.srcElement.nodeName;

      const entityDetailRoute = this.detailLink;
      let entityServiceRoute = `${entityDetailRoute}/services`;

      if (eventSourceNodeName === buttonNodeName) {
        if (!entityServiceRoute.startsWith("/tourist") && !this.is_anonymouse) {
          entityServiceRoute = `/tourist${entityServiceRoute}`;
        }

        if (!this.isLoggedIn && this.is_anonymouse) {
          localStorage.setItem(
            "entity_services_redirect",
            `/tourist/${entityServiceRoute}`
          );
        }

        this.$router.push(entityServiceRoute);
      } else {
        this.$router.push(entityDetailRoute);
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn"]),
  },
};
</script>

<style scoped>
.absolute {
  /* position: absolute;
  top: 0;
  left: 0;
  height: 100%; */
  width: 100%;
  height: 100%;
}

.bottom-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.outer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;
  width: inherit;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.2);
}

img {
  height: 230px;
  max-height: 230px;
  width: 350px;
  max-width: 350px;
  opacity: 0.7;
  object-fit: cover;
  position: center;
}

.card {
  height: 230px;
  max-height: 230px;
  width: 300px;
  /* max-width: 350px; */
  color: white;
  background: black;

  transition: 0.3s;
  border-radius: 5px;
  cursor: pointer;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.ratings {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  align-items: baseline;
  align-content: flex-end;
}

.rating {
  color: white;
  font-weight: bolder;
  font-size: 1.3rem;
  margin-left: 10px;
}

.title {
  font-weight: bolder;
  font-size: 1.4rem;
}

.location {
  color: #fadb14;
}

.location .region {
  margin-left: 10px;
}
</style>
